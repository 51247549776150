import baseTheme from '@littleplusbig/gatsby-theme-faqs-prismic/src/gatsby-plugin-theme-ui';
import {theme} from '../../tailwind.config';

export default {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: '#F8F5EF',
    primaryDark: '#A8E8B5',
    primaryLight: '#E3F5E4',
    offWhite: '#F8F5EF',
    controlBorder: '#A8E8B5',
    controlBackground: '#E3F5E4',
    controlBorderActive: '#A8E8B5',
    controlBackgroundActive: '#A8E8B5',
    controlBackgroundHover: '#A8E8B5',
    controlBorderHover: '#A8E8B5',
    text: '#303030',
    controlText: '#303030',
    controlTextActive: '#303030',
    placeholderText: '#303030',
  },
  fonts: {
    heading: theme.extend.fontFamily.header.join(),
    body: theme.extend.fontFamily.body.join(),
  }
};
