module.exports = {
  theme: {
    extend: {
      fontFamily: {
        header: ['League Spartan', 'sans-serif'],
        body: ['Nunito', 'sans-serif'],
      },
      colors: {
        primary: {
          lighter: 'hsl(207, 73%, 52%)',
          default: 'hsl(207, 73%, 57%)',
          darker: 'hsl(207, 73%, 44%)',
        },
        yellow: '#F8D899',
        orange: '#FF914D',
        green: '#A8E8B5',
        gray: '#F8F5EF',
        blue: '#95BED1',
        pine: '#9FC7AA',
        gold: '#FFE792',
      },
      fontWeight: {
        'header': '751',
      },
    },
  },
  variants: {},
  plugins: [],
};
